import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import vanImage from '../images/van.webp';


const services = [
    {
        heading: 'Support',
        body: 'From day one we are listening to your dreams and transporting them into reality.'
    },
    {
        heading: 'Strategy',
        body: 'We understand the process of creating a timeline and concurrent workflows. The result is a quality product without any hassles.'
    },
    {
        heading: 'Management',
        body: 'Since we are the team, we know everyone on the worksite and vouch for every worker in your home.'
    },
    {
        heading: 'Quality',
        body: 'When building cabinets, counters, furniture precision counts. We have invested in the highest rated equipment to deliver every job.'
    },
    {
        heading: 'Consulting',
        body: 'We communicate with you from the start of the job through delivery, you will always be able to reach us and we will proactively keep you up to date.'
    },
    {
        heading: 'Big or Small',
        body: 'Every job is important to us. No job is "small" and we have tackled some big projects. Every job gets the same detailed attention.'
    },
]

export function Team() {
    return (
        <Container sx={{ display: "flex", flexWrap: 'wrap', flexDirection: 'column' }}>
            <Box sx={{ width: '100%', height: { md: '500px', xs: '200px' }, backgroundSize: '100%', backgroundImage: `url(${vanImage})` }}>
            </Box>
            <Box sx={{ textAlign: { xs: 'left', md: 'center' }, p: 6 }}>
                <Stack spacing={2}>
                    <Typography variant="h3">We are creative thinkers who have built a business to change the world.</Typography>
                    <Typography variant='body1'>
                        We love to create. We build from scratch so everything "just fits" and we are proud of the work we do.
                        As a father-son team, we have grown our business throughout the Tampa Bay Area, building custom
                        solutions for homes and commercial real estate. Our portfolio is growing and we would love to have
                        the opportunity to work with you.
                    </Typography>
                </Stack>
            </Box>
            <Box sx={{ textAlign: 'center', p: 6 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h3">Our Services</Typography>
                        <Typography variant="caption">WHAT WE ARE BEST AT</Typography>
                    </Grid>

                    {services.map((service) => [
                        <Grid item xs={12} md={4} sx={{ textAlign: 'left' }}>
                            <Typography variant="h6">{service.heading}</Typography>
                            <Typography variant='body1'>{service.body}</Typography>
                        </Grid>
                    ])}
                </Grid>
            </Box>
        </Container >
    )
}