import { Box, Button, Card, CardContent, CardMedia, Container, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { externalNavigate } from "../helpers";
import headerImage from '../images/landing.webp';
import nahbLogo from '../images/logos/nahb_logo.png';
import fhbaLogo from '../images/logos/fhba_logo.png';
import tbbaLogo from '../images/logos/tbba_logo.jpg';
import ProjectTimeline from "./ProjectTimeline";
import ProjectTimelineMobile from "./ProjectTimeline2";
import ServicesGrid from "./ServicesGrid";
import Grid from '@mui/material/Unstable_Grid2';
import vanImage from '../images/van.webp';
import PeopleIcon from '@mui/icons-material/People';

const orgLogos = [
    tbbaLogo,
    fhbaLogo,
    nahbLogo
]


export function Landing() {
    const navigate = useNavigate();
    return (

        <Container sx={{ display: "flex", flexWrap: 'wrap', flexDirection: 'column' }}>

            <Box sx={{ width: '100%', height: { md: '500px', sm: '350px', xs: '250px' }, backgroundSize: '100%', backgroundPositionY: 'center', backgroundImage: `url(${headerImage})` }}>
                <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', bgcolor: 'rgba(0, 0, 0, 0.4)', color: '#FFFFFF' }}>
                    <Box sx={{ p: 1 }}>
                        <Typography variant="h3">You dream it. We build it.</Typography>
                        <Typography variant="h6">Furniture you will love.</Typography>
                        <Stack spacing={1} direction={'row'} sx={{ justifyContent: 'center' }}>
                            <Button onClick={() => { navigate('/gallery') }} variant="outlined" size="large" sx={{ color: '#FFFFFF', borderColor: '#FFFFFF' }}>View our work</Button >
                            <Button onClick={() => { externalNavigate('tel:813-215-3262') }} variant="contained" disableElevation size="large" sx={{ color: '#FFFFFF', borderColor: '#FFFFFF' }}>Call Us </Button>
                        </Stack>
                    </Box>
                </Box>
            </Box>

            {/* Section: Service type cards */}
            <Box sx={{ textAlign: 'center', p: 0, py: 4 }}>
                <ServicesGrid></ServicesGrid>
            </Box>

            {/* Section: About and process */}
            <Box sx={{ p: 0, py: 4 }}>
                <Grid container rowSpacing={6} spacing={2} columns={12}>
                    <Grid item xs={12} sm={6} md={6} sx={{ textAlign: { xs: 'left', md: 'left', } }}  >
                        <Card elevation={0} sx={{ height: '100%', maxWidth: '100%', p: 0, color: 'text.secondary', }}>
                            <CardMedia>
                                <Box sx={{ width: '100%', height: { md: '220px', xs: '200px', sm: '130px' }, backgroundSize: '100%', backgroundPositionY: '0px', backgroundImage: `url(${vanImage})` }}>
                                </Box>
                            </CardMedia>
                            <CardContent sx={{ px: 0, textAlign: 'center', }}>
                                <PeopleIcon></PeopleIcon>
                                <Typography gutterBottom variant="h5" component="div">
                                    Who We Are
                                </Typography>
                                <Typography variant="body2">
                                    We love to create. We build from scratch so everything "just fits" and we are proud of the work we do.
                                    As a father-son team, we have grown our business throughout the Tampa Bay Area, building custom
                                    solutions for homes and commercial real estate. Our portfolio is growing and we would love to have
                                    the opportunity to work with you.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ textAlign: { xs: 'left', md: 'left', } }} >
                        <Card elevation={0} sx={{ height: '100%', maxWidth: '100%', p: 2, color: 'text.primary', backgroundColor: 'secondary.main' }}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Our Process
                                </Typography>
                                <ProjectTimeline></ProjectTimeline>
                                <ProjectTimelineMobile></ProjectTimelineMobile>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            {/*   Financing offer
            <Box sx={{ textAlign: 'center', p: 6 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'left', md: 'center' }, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="h4">Need financing?</Typography>
                        <Typography variant='body1'>
                            We have teamed up with Hearth Financial and can offer 0% down or a fast
                            and easy credit approval right on our website.
                        </Typography>                    </Grid>
                    <Grid item xs={6} md={4}>
                        <CardActionArea onClick={() => { externalNavigate('https://app.gethearth.com/partners/ecv-construction-inc?utm_campaign=32139&utm_content=darkblue&utm_medium=contractor-website&utm_source=contractor&utm_term=310x610') }}>
                            <img src="https://app.gethearth.com/contractor_images/ecv-construction-inc/banner.jpg?color=darkblue&size_id=310x610" alt="Hearth Banner" style={{ width: '100%' }}></img>
                        </CardActionArea>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <CardActionArea onClick={() => { externalNavigate('https://app.gethearth.com/partners/ecv-construction-inc?utm_campaign=32139&utm_content=zero_percent&utm_medium=contractor-website&utm_source=contractor&utm_term=310x610') }}>
                            <img src="https://app.gethearth.com/contractor_images/ecv-construction-inc/banner.jpg?color=zero_percent&size_id=310x610" alt="Hearth Banner" style={{ width: '100%' }}></img>
                        </CardActionArea>
                    </Grid>
                </Grid>
            </Box>
            */}

            {/* Section: Association logos */}
            <Box sx={{ textAlign: 'center', p: 6, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                {orgLogos.map((logo, index) => [
                    <Box key={index} sx={{ width: { xs: '65px', sm: '140px', md: '200px' } }}>
                        <img src={logo} style={{ width: '100%' }} alt=""></img>
                    </Box>
                ])}
            </Box>
        </Container >

    )
}