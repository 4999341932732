import { Container } from '@mui/material';
import * as React from 'react';
import { useState, useEffect } from 'react';
import GalleryOptionsSpeedDial from './GalleryOptionsSpeedDial';
import Gallery from './Gallery/Gallery';



const API_BASE_URL = "https://servifoto.ernestodiaz.dev/api/collections";
    const gallery_ids = {
        Residential: 2,
        Commercial: 3
    }

export function Portfolio() {
    const [galleries, setGalleries] = useState(new Map());
    const [selectedGallery, setSelectedGallery] = useState([]);

    useEffect(() => {
        const fetchGalleries = async () => {
            const residentialResp =  await fetch(`${API_BASE_URL}/${gallery_ids["Residential"]}/images?paginate=false`)
            const commercialResp = await fetch(`${API_BASE_URL}/${gallery_ids["Commercial"]}/images?paginate=false`)

            const residentialImages =  await residentialResp.json()
            const commercialImages = await commercialResp.json()

            let residentialUrls = [];
            residentialImages.forEach(img => {
                residentialUrls.push(img.filePath);
            });

            let commercialUrls = [];
            commercialImages.forEach(img => {
                commercialUrls.push(img.filePath);
            });

            const newGalleries = new Map();
        newGalleries.set("Residential", residentialUrls);
        newGalleries.set("Commercial", commercialUrls);

        setGalleries(newGalleries);
        setSelectedGallery(residentialUrls);
        };
        fetchGalleries();
    }, []);

    
    

    const selectionHandler = (galleryName) => {
        setSelectedGallery(galleries.get(galleryName));
        console.log(galleryName);
        console.log(selectedGallery);
    }

    return (
        <Container>
            <GalleryOptionsSpeedDial selectionHandler={selectionHandler} />
            <Gallery images={selectedGallery} ></Gallery>
        </Container >
    )
}
