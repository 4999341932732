import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

export default function StepCard(props) {
    return (
        <Box>

            <Typography gutterBottom variant="h5" component="div">
                {props.title}
            </Typography>
            <Typography variant="body2" color="text.primary">
                {props.description}
            </Typography>
        </Box>
    );
}
