import { Backdrop, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './ImageViewer.css';
import { useState } from 'react';

function ImageViewer(props) {

    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50

    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe) {
            props.switchImage(validateIndex(props.selectedImage + 1, props.images))
        }
        if (isRightSwipe) {
            props.switchImage(validateIndex(props.selectedImage - 1, props.images))
        }
    }

    const validateIndex = (i, a) => {
        if (i < 0) {
            return 0;
        }
        if (i >= a.length) {
            return a.length - 1;
        }
        return i;
    }

    return (
        <Backdrop
            sx={{
                bgcolor: '#000',
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open={!props.hidden}
        >


            <Box>
                <IconButton

                    onClick={() => props.hideViewer()}
                    sx={{
                        color: 'white',
                        position: 'absolute',
                        top: '30px',
                        right: '30px',
                    }}>
                    <CloseIcon fontSize='large' />
                </IconButton>
            </Box>
            <Box sx={{
                width: '85%',
                height: '80%',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                overflow: 'clip'
            }}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}>
                <img style={{ maxHeight: '100%', maxWidth: '100%' }}
                    src={props.images[props.selectedImage]} alt='Work sample' />
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '30px',
                    left: 'auto',
                    right: 'auto'
                }}
            >
                <IconButton
                    onClick={() => props.switchImage(
                        validateIndex(props.selectedImage - 1, props.images))}
                    sx={{
                        color: 'white',

                    }}>
                    <ArrowBackIosNewIcon fontSize='large' />
                </IconButton>
                <IconButton
                    onClick={() => props.switchImage(
                        validateIndex(props.selectedImage + 1, props.images))}
                    sx={{
                        color: 'white',


                    }}>
                    <ArrowForwardIosIcon fontSize='large' />
                </IconButton>
            </Box>





        </Backdrop>
    )
}

export default ImageViewer