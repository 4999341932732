import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActions } from '@mui/material';

export default function ServiceCard(props) {

    return (
        <Card elevation={0} sx={{ height: '100%', maxWidth: '100%', p: 2, border: 'solid', borderColor: 'secondary.main', color: 'text.secondary' }}>
            <CardMedia>
                {props.media}
            </CardMedia>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {props.title}
                </Typography>
                <Typography variant="body2" color="secondary.main">
                    {props.description}
                </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center', color: 'info.main' }}>
                {props.action}
            </CardActions>
        </Card>
    );
}
