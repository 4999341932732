import React, { useState } from "react";
import './Gallery.css'
import ImageViewer from "../ImageViewer/ImageViewer";
import { Button, ImageList, ImageListItem } from "@mui/material";


const sizes = [].concat(...Array.from({ length: 84 }, () => [1, 1, 2, 2, 1, 1]));

function Gallery(props) {

    const [viewerHidden, setViewerHidden] = useState(true);
    const [selectedImage, setSelectedImage] = useState();
    const [displayCount, setDisplayCount] = useState(12);

    const fetchMoreData = () => {
        setDisplayCount(displayCount + 12);
    };

    const selectImage = (image) => {
        setSelectedImage(image);
        setViewerHidden(false);
        document.body.style.overflow = 'hidden'
    }

    const hideViewer = () => {
        setViewerHidden(true)
        document.body.style.overflow = 'auto'
    }

    return (
        <>
            <ImageList variant="quilted" cols={4}>
                {props.images.slice(0, displayCount).map((image, index) => (
                    <ImageListItem key={index} cols={sizes[index] || 1} rows={sizes[index] || 1} onClick={() => selectImage(index)}>
                        < img
                            src={`${image}?fit=crop&auto=format`}
                            key={index}
                            alt="Test"
                            loading="lazy" />
                    </ImageListItem>
                ))}
            </ImageList>

            <Button size="large" sx={{ width: '100%', py: 3 }} onClick={fetchMoreData}>More </Button>
            <ImageViewer
                hideViewer={hideViewer}
                selectedImage={selectedImage}
                images={props.images.slice(0, displayCount)}
                hidden={viewerHidden}
                switchImage={setSelectedImage} />
        </>

    );
}

export default Gallery