import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import ServiceCard from './ServiceCard';
import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import StraightenIcon from '@mui/icons-material/Straighten';

export default function ServicesGrid() {
    return (
        <Grid container rowSpacing={6} spacing={2} columns={12}>
            <Grid item xs={12} sm={4} md={4}>
                <ServiceCard
                    title="Commercial"
                    description="Got a big project planned? We have the tools and expertise to make it a reality. We will help you get your business up and running."
                    media={<BusinessIcon sx={{ fontSize: 60 }}></BusinessIcon>}
                >
                </ServiceCard>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
                <ServiceCard
                    title="Residential"
                    description="From the kitchen to the bathroom and every room in between, our cabinets and counters will bring utility, beauty, and comfort to your home."
                    media={<HomeIcon sx={{ fontSize: 60 }}></HomeIcon>}
                >
                </ServiceCard>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
                <ServiceCard
                    title="Custom Made"
                    description="Our furniture is custom built to meet your needs. Small spaces, unusual layouts, crazy ideas... We'll get it done!"
                    media={<StraightenIcon sx={{ fontSize: 60 }}></StraightenIcon>}

                >
                </ServiceCard>
            </Grid>
        </Grid >
    );
}
