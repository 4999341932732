import { Box } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import './App.css';
import { Footer } from './components/Footer';
import ResponsiveAppBar from './components/ResponsiveAppBar';

const theme = createTheme({
  palette: {
    primary: {
      main: '#DF6F5B',
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#121D21',
      contrastText: '#F7F7F7',
    },
    info: {
      main: '#237371',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#000000'
    }
  },
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', minHeight: '100%', position: 'relative', display: 'flex', flexDirection: 'column' }}>
        <Box id='header' sx={{ width: '100%' }}>
          <ResponsiveAppBar></ResponsiveAppBar>
        </Box>
        <Box id="content" sx={{ flex: 1, width: '100%', mt: { md: '10px', xs: '130px' }, mb: '10px' }}>
          <Outlet />
        </Box>
        <Box id='footer' sx={{ width: '100%' }}>
          <Footer></Footer>
        </Box>
      </Box >
    </ThemeProvider>
  );
}

export default App;
