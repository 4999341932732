import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DraftsIcon from '@mui/icons-material/Drafts';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MapIcon from '@mui/icons-material/Map';
import { Box, Container, Stack, Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { externalNavigate } from '../helpers';

export function Footer() {

    const contacts = [
        { icon: null, text: 'ECV Construction, Inc.', url: '' },
        { icon: (<DraftsIcon />), text: 'ecvconstruction45@gmail.com', url: 'mailto:ecvconstruction45@gmail.com' },
        { icon: (<ContactPhoneIcon />), text: '813-215-3262', url: 'tel:813-215-3262' },
        { icon: (<ContactPhoneIcon />), text: '813-573-2131', url: 'tel:813-573-2131' },
        { icon: (<MapIcon />), text: '8009 Benjamin Rd, Tampa, FL 33634', url: 'https://www.google.com/maps?ll=28.022722,-82.541368&z=16&t=h&hl=en&gl=US&mapclient=embed&cid=5076629334269934077' }
    ]

    const socials = [
        { icon: (<InstagramIcon />), text: 'Follow us on Instagram', url: 'https://www.instagram.com/ecvconstructioninc/' },
        { icon: (<FacebookIcon />), text: 'Like us on Facebook', url: 'https://www.facebook.com/search/top?q=ecv_construction_inc' }
    ]

    return (
        <Box sx={{
            display: 'flex', p: 4,
            maxWidth: '100%', m: 0,
            bgcolor: 'primary.main',
            color: '#FFFFFF', justifyContent: { xs: 'flex-start', md: 'space-evenly' },
            flexWrap: 'wrap'
        }}>
            <Container sx={{
                display: 'flex', justifyContent: { xs: 'flex-start', md: 'space-evenly' },
                flexWrap: 'wrap'
            }}>
                <Box sx={{ p: 1 }}>
                    <Stack>
                        <Typography variant="h6">Stay in the know:</Typography>
                        <List>
                            {socials.map((social, index) => [
                                <ListItem key={index} disablePadding>
                                    <ListItemButton onClick={() => externalNavigate(social.url)}>
                                        <ListItemIcon sx={{ color: '#ffffff' }}>
                                            {social.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={social.text} />
                                    </ListItemButton>
                                </ListItem>
                            ])}
                        </List>
                    </Stack>
                </Box>
                <Box id='contact-info' sx={{ p: 1 }}>
                    <Stack>
                        <Typography variant="h6">Contact us:</Typography>
                        <List>
                            {contacts.map((contact, index) => [
                                <ListItem key={index} disablePadding>
                                    <ListItemButton onClick={() => externalNavigate(contact.url)} >
                                        <ListItemIcon sx={{ color: '#ffffff' }}>
                                            {contact.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={contact.text} />
                                    </ListItemButton>
                                </ListItem>
                            ])}
                        </List>
                    </Stack>
                </Box>
                <Box sx={{
                    display: 'flex', pt: 3,
                    width: '100%',
                    bgcolor: 'primary.main',
                    justifyContent: 'center',
                }}>
                    <Typography variant='caption'>2024 © ECV CONSTRUCTION INC | Website by: <a href='https://ernestodiaz.dev/' style={{ color: '#ffffff' }}>ernestodiaz.dev</a></Typography>
                </Box>
            </Container>
        </Box>
    )
}