import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import StepCard from './StepCard';

import SquareFootIcon from '@mui/icons-material/SquareFoot';
import CarpenterIcon from '@mui/icons-material/Carpenter';
import HardwareIcon from '@mui/icons-material/Hardware';

export default function ProjectTimelineMobile() {
    return (
        <Timeline sx={{
            [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
            },
            display: { xs: "flex", md: "none" }
        }}>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot color="primary">
                        <SquareFootIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <StepCard
                        title="Measure"
                        description="We will measure your spaces, ask you about your appiances, and gather details about your design ideas.">
                    </StepCard>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot color="primary">
                        <CarpenterIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <StepCard
                        title="Build"
                        description="Back at the workshop, we will build your furniture piece by piece, using state of the art equipment to ensure the most precise cuts. ">
                    </StepCard>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot color="primary">
                        <HardwareIcon />
                    </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                    <StepCard
                        title="Install"
                        description="Once the cutting is done, we will come back to your place and assemble the furniture.">
                    </StepCard>
                </TimelineContent>
            </TimelineItem>
        </Timeline >
    );
}